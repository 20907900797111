/*
bootstrapとmaterial uiのButtonは
aタグとしてもbuttonタグとしても使われるから
注意が必要
*/
.home-buttons-wrap {
    display:inline-block;
    justify-content: justify;
    text-align: center;
}
a.button-main-txt, button.button-main-txt {
    font-size: 22px;
    border-color: rgb(255, 166, 42);
    border-width: 2px;
    border-radius: 100%;
    margin: 15px;
    width: 240px;
    height: 240px;
    display:inline-flex; justify-content: center; align-items: center;
}
a.button-main-txt:hover, button.button-main-txt:hover {
    font-weight: bold;
    background-color: rgba(213, 124, 0, 0.86);
    border-color: rgb(255, 166, 42);
}