.Home-one-line-container {
    display:flex;
}
.MyIcon {
    text-align: center;
}
.MyIcon img {
    width: 300px;
    border-radius: 50%;
}
.CommentForVisitor {
    padding-left: 10px;
}
@media screen and (max-width: 600px) {
	/* 480px以下に適用されるCSS（スマホ用） */
    .Home-one-line-container {
        display:block;
    }
}
.MyResearch {
    text-align: center;
}
.MyResearch img {
    width: 300px;
    border-radius: 50%;
}




div.home-link-thumbnail {
    display:inline-block;
    margin-right:10px;
}

img.home-link-thumbnail-img {
    height:300px;
}

div.home-information {
    overflow-y:scroll;
    height:400px;
    border: 5px solid rgba(213, 124, 0, 0.86);
    border-radius: 10px;
    padding: 10px;
}
span.news-new-wrap {
    font-weight: bold;
    padding: 5px;
    margin-right: 5px;
    border-radius: 9px;
}