section {
    margin-bottom: 80px;
}

h1.page-type-name {
    font-size: 50px;
    font-weight: bold;
    line-height: 1.3em;
    padding-top: 10px;
    border-bottom: 8px solid;
}
h1.page-title {
    font-size: 40px;
    font-weight: bold;
}
h1.page-subtitle {
    font-size:18px;
    font-weight: bold;
}

h2.section-title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    border-bottom: 4px solid;
    border-image: linear-gradient(to right,
        transparent,
        white,
        transparent
    );
    border-image-slice: 1;
    padding-bottom: 3px;
    margin: 80px 0 20px 0;
}

h3.section-title {
    font-size: 32px;
    font-weight: bold;
    border-bottom: 1.5px solid;
    border-image: linear-gradient(to right,
        white,
        transparent
    );
    border-image-slice: 1;
    padding-bottom: 3px;
    margin: 20px 0 10px 0;
}


/* 小見出し */


.page-title-abstract-paragraph {
    font-size: 20px;
    text-align: center;
}
p {
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: justify;
}

.card-title {
    text-align: justify;
}
div.h5 {
    font-size: 26px;
}

/* 文字の下線での強調用CSS */
span.txt-underline {
    font-weight: bold;
    padding: 2px;
    border-radius: 4px;
    border-bottom: 3px solid rgb(255, 255, 255);
    border-image: linear-gradient(
        to right,
        rgb(192, 114, 5),
        rgb(255, 180, 76),
        rgb(192, 114, 5)
    );
    border-image-slice: 1;
}