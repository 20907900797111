header {
    width: 100%;
    min-width: 460px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
}
header .mb-3 {
    background-color: rgb(152, 89, 0);
    justify-content: space-between;
    width: 100%;
    border-bottom: 3px solid rgb(181, 105, 0);
}
span.share_button {
    margin-right: 10px;
}

div.offcanvas-end {
    background-color: rgb(152, 89, 0);
}
a.dropdown-toggle:link,
a.dropdown-toggle:visited,
a.dropdown-toggle:hover,
a.dropdown-toggle:active {
    color:antiquewhite;
}


.HeadMyIcon-img {
    height:80px;
    border-radius:50%;
}
.HeadNavBar-title-txt {
    font-size: 26px;
}
.HeadNavBar-subtitle-txt {
    font-size: 22px;
}
@media screen and (max-width: 736px) {
    .HeadMyIcon-img {
        height:60px;
        border-radius:50%;
    }
    .HeadNavBar-title-txt {
        font-size: 22px;
    }
    .HeadNavBar-subtitle-txt {
        font-size: 16px;
    }
}

a.nav-link {
    font-size: 22px;
}
a.dropdown-item {
    font-size: 20px;
}

div.offcanvas-body {
    width: 100%;
}

div.dropdown-menu {
    background-color: rgb(181, 105, 0);
}