div .root {
  padding-top: 100px;
  width: 100%;
  text-align: center;
}
html {
  width: 100%;
  min-width: 460px; /* これ必須 */
}
div.react-root {
  
  overflow: hidden; /*  無駄な余白対策に必須 */
  min-width: 460px;
  padding-top: 120px;
  padding-bottom: 90px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1200px;
  margin: auto;/*0;*/
  text-align: left;
  /* font */
  font-size: 22px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  line-height: 1.6em;
}
@media screen and (max-width: 736px) {
  .react-root {
    padding-top: 90px;
    font-size: 20px;
  }
}
@media screen and (max-width: 661px) {
	/*  */
    .react-root {
      padding-top: 160px;
      font-size: 18px;
    }
}


a:link, a:visited, a:hover, a:active {
  color:antiquewhite;
}


div.title-area {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.page-title-area {
  display: inline-block;
}

code.language-python {
  background-color: rgb(0, 0, 0);
  font-weight: bold;
  color:antiquewhite
}

code.language-typescript {
  background-color: rgb(0, 0, 0);
  font-weight: bold;
  color:antiquewhite
}
span.hljs-string {
  color:antiquewhite;
}
span.hljs-attr {
  color:aqua;
}

p.insert-column-container {
  background-color: rgb(175, 102, 0);
  border-style:solid;
  border-width: 3px;
  border-color: antiquewhite;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

