footer {
    width: 100%;
    min-width: 460px;
    align-items: center;
    bottom: 0; /* 下部に固定 */
    z-index: 9;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 3px solid rgb(181, 105, 0);
    padding-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
}
.footer-inner-wrap {
    overflow: hidden; /*  無駄な余白対策に必須 */
    min-width: 450px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
}
footer h4 {
    position: relative;
    padding: 5px 10px;
    border-left: 10px double;
    font-weight: bold;
}
footer a:link, footer a:visited, footer a:hover, footer a:active {
    color:antiquewhite;
}
div.MuiPaper-root {
    background-color: rgb(152, 89, 0);
    color: antiquewhite;
    padding: 20px;
}

.share_page_button {
    display: inline-flex;
}
.share_button {
    margin: 5px;
}
.cc-licence-button {
    margin: 25px;
    width: 150px;
}