.chart-hide-scrollbar::-webkit-scrollbar {
    display: none; /* WebKitブラウザでのスクロールバー非表示 */
}

.chart-hide-scrollbar {
    overflow: scroll;
    -ms-overflow-style: none;  /* IE、Edgeでのスクロールバー非表示 */
    scrollbar-width: none;  /* Firefoxでのスクロールバー非表示 */
}

.chart-wrapper {
    display: flex;
    justify-content: center;
}