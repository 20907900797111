div.peer-reviewed-publication-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-evenly;
}

div.peer-reviewed-publications-card {
    width: 575px; /*380px;*/
    border-top: 3px solid;
    border-left: 3px solid;
    border-bottom:10px solid;
    border-right: 10px solid;
    border-color:rgb(228, 174, 97);
    /*background-color: transparent;*/
    background-color: rgb(137, 81, 2);
    margin-bottom: 15px;
}
button.nav-link {
    color:blanchedalmond;
}
button.nav-link:hover {
    background-color: rgb(255, 149, 0);
    color:azure;
    font-weight: bold;
}
button.nav-link:active {
    background-color: rgb(255, 149, 0);
    font-weight: bold;
}

div.altmetric-popover-content {
    background-color: inherit;
}
div.link-to-altmetric-details-tab{
    background-color: inherit;
}

.reviewed_badge {
    background-color: rgb(20, 152, 223);
    color:white;
    padding: 5px 15px;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
}
.not_reviewed_badge {
    background-color: rgb(159, 127, 0);
    color:white;
    padding: 5px 15px;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
}

div.modal-content {
    border-color: rgb(159, 127, 0);
    border-width: 4px;
}