div.about-me-title-area-container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

img.about-me-img {
    width: 30%;
    height: 30%;
    border-radius: 50%;
}

div.about-me-title-area {
    width: 70%;
    padding: 20px;
}